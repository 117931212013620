.sidebar {
  text-align: left;
}

/* when sidebar opens, hamburger icon turns into 'x' */
.app-nav-open {
  padding-left: 280px;
}

body,
div,
span,
p {
  font-size: 0.9rem;
  line-height: 1.3em;
  /* margin-bottom: 0.5em; we need something like this, but it currently introduces problems with height of other fields, and adds an extra scrollbar on the right of the list. */
}

/* We use <hr> mostly to separate items in our main list. */
hr {
  border: 1px solid #f0f0f0;
  margin-right: 2rem;
}

.top-nav-component {
  position: sticky;
  top: 0;
  z-index: 1;
  flex-grow: 0;
}

/* All icons in top nav */
/* hack; some of these are meant only for hamburger, but we want the unset focus for all of them, so for now we use this for them all */
.topnav-icon {
  display: flex;
  align-items: center;
}
.topnav-icon button:focus {
  box-shadow: unset;
}

/* Hack; special case for caret next to collection name. These can be removed when we figure out how to use a real menu here. */
.caret-icon {
  margin-left: -0.5rem;
}
.caret-icon button:focus {
  box-shadow: unset;
}

/* Hamburger menu */
.side-nav-container {
  text-align: left;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0px;
  height: 100vh;
  width: 280px;
  z-index: 2;
  overflow-x: hidden;
  background: #f5f5f5;
  box-shadow: rgb(72, 83, 102) -5px 0px 8px -5px inset;
}

/* Top nav items are links, but not styled like links. */
.topnav a {
  color: white;
  text-decoration: none;
}

.topnav a:hover {
  color: #ddd;
}

/* Adsk icon and search bar position */
.top-nav {
  position: relative;
  display: flex;
  vertical-align: middle;
  align-items: center;
  flex-grow: 1;
}

/* Adsk icon size */
.top-nav img {
  height: 24px;
}

/* Website title in navbar */
.site-title {
  padding-left: 3px;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 14pt;
  font-weight: 900;
  letter-spacing: -0.03rem; /* tighten up this text a bit */
}

/* Separator in navbar */
.separator {
  margin: auto 0.75rem; /* left and right margins */
  width: 1px;
  height: 2rem;
  background-color: rgb(212, 212, 212);
}

/* Spacer in navbar */
.spacer {
  margin: auto 0.2rem; /* left and right margins */
  width: 1px;
  height: 2rem;
}

/* Currently displayed collection */
.current-collection {
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 12pt; /* should agree with tab-count */
  font-weight: 700; /* should agree with tab-count */
  color: rgb(100, 100, 100);
  letter-spacing: -0.02rem; /* tighten up this text a bit */
}
.current-collection svg {
  margin-left: 5px;
}

/* Search area, which contains the main search box and "found items" count */
.search {
  margin-left: 5%; /* should match .topnav-right > margin-left, to center search stuff */
  display: flex;
  align-items: center;
  width: 100%; /* without this (~70+%), the underline is too short */
}

/* Input text in search field */
input[type="text"],
select {
  padding-top: 4px;
  font-size: 16px;
}

.search label {
  display: flex;
  padding: 0 5px 0 10px;
}

.search input {
  flex-grow: 1;
}

/* Search bar ✕ clear string button */
.clear-search {
  background: transparent;
  border: none;
  position: absolute;
  right: 0;
  padding-top: 4px;
  fill: #999999;
}

.clear-search:hover #path-1 {
  fill: #3c3c3c;
}

.record-count {
  display: flex;
  color: rgba(85, 85, 85, 0.63); /* should agree with tab-count */
  font-size: 11pt; /* should agree with tab-count */
  font-weight: 900; /* should agree with tab-count */
  margin-left: 20px;
}

/* Container for right side of NavBar */
.topnav-right {
  display: flex;
  align-items: center;
  padding-right: 15px; /* give rightmost button some distance from edge */
  outline: none; /*this does not suppress focus ring -- why?*/
  justify-content: flex-end; /* what is this for? */
  margin-left: 5%; /* should match .search > margin-left, to center search stuff */
}

/* User-uploaded file styles */
input.csv-input {
  max-width: 185px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

/* Dataset tab labels and counts, for nonzero and zero-count situations. */

.tab-label {
  font-weight: 600;
  font-size: 12pt;
  white-space: nowrap;
}

.tab-label-empty {
  /* tab labels when item count is zero */
  color: rgba(0, 0, 0, 0.2);
  font-weight: 600;
  font-size: 12pt;
  white-space: nowrap;
}

.tab-count {
  /* item counts next to tab labels */
  color: rgba(85, 85, 85, 0.63); /* should agree with record-count */
  font-size: 11pt; /* should agree with record-count */
  font-weight: 900; /* should agree with record-count */
  margin-left: 5px;
}

.tab-count-empty {
  /* item counts next to tab labels, when item count is zero */
  color: rgba(0, 0, 0, 0.2);
  font-weight: 600;
  font-size: 12pt;
  margin-left: 5px;
}

/* not sure why <Typography> doesn't cover this */
/* bc: <Typography> has either a className of "App app-nav-open" 
or "App" depending on whether sideNavIsOpen evaluates to truthy */
input[type="file"] {
  font-family: "ArtifaktElement";
}

/* not sure why <Typography> doesn't cover this */
button {
  font-family: "ArtifaktElement";
}

/* Input box placeholder text color in various browsers. */
::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ccc;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #ccc;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #ccc;
}

.top-nav-container__top-nav-logo-wrapper {
  flex-grow: 1;
}

/* HIG search presentation component */
.top-nav-container__top-nav-spacer {
  display: none;
}

/* Styling for the main list of records. */
.card {
  list-style: none;
  text-align: left;
  padding: 20px;
  width: 100%;
  margin-left: -42px; /* ...or another */
}

.card a {
  color: rgb(73, 129, 194);
  text-decoration: none;
}

/* Hint of color when cursor hovers over each entry */
ul li:hover {
  /* Safari */
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s;
  background-color: #00000008;
}

.card li {
  color: #222222;
  text-decoration: none;
  display: block;
}

.card[data-selected="true"] {
  font-weight: bold;
}
/* ?? */
.hide {
  display: none;
}

/* Conf room names */
.room {
  font-size: 1.5rem;
  line-height: 2rem;
}

/* Standard formatting for field labels. */

.field-label {
  width: calc(300px - 1rem);
  min-width: calc(300px - 1rem);
  text-align: right;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
  color: rgb(128, 128, 128);
  padding-right: 1rem;
  margin-top: 0.1rem; /* pushes it own to baseline-align with field content */
}

/* This applies to section labels within our flyouts.
   They should look similar to standard field labels,
   but without fixed width or right alignment. */

.flyout-label {
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
  color: rgb(128, 128, 128);
  margin-bottom: 0.6rem;
  margin-top: 0.6rem;
}

/* This applies to the dataset label within our "info" flyout.
   For visual consistency, we keep the case and weight similar
   to the corresponding tab label. But then it kind of runs into
   the dataset info below. So we play with the weight and placement
   within the flyout a bit to visually separate the two. */

.flyout-dataset-label {
  font-weight: 700;
  margin-top: -0.5rem;
  margin-bottom: 0.6rem;
}

/* Standard formatting for field values. */

/* currently unused */
/* .field-value {
  text-align: left;
  word-break: break-word;
} */

.field {
  display: flex;
  padding-bottom: 0.25rem;
}

/* These apply to the main list area. */

.mainList,
.mainListWithDetail {
  z-index: 0;
}
.mainList table {
  padding: 1.25rem;
}

.mainList li span {
  display: block;
}

.mainList li .notes {
  font-style: italic;
}

.mainList li span.highlight {
  display: inline;
  background-color: yellow;
  font-size: inherit;
}

.mainList {
  width: 100%;
}

.mainListWithDetail {
  width: calc(100vw - 100vh + 59px);
}

.mainList,
.mainListWithDetail {
  overflow-y: auto;
  height: 100%;
}

.mainListWithDetail li span {
  display: block;
}

.mainListWithDetail li .notes {
  font-style: italic;
}

.mainListWithDetail li .notes {
  display: none;
}

.mainListWithDetail li .seats {
  display: none;
}

.mainList ul,
.mainListWithDetail ul {
  margin: 0;
}

.noResult {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-style: italic;
}

.noResult img {
  margin: 20vh;
  opacity: 0.04;
}

/* Styling for the detail area, which contains an image that shows the selected room. */

.detail {
  /* 95px needs to be for width too to keep image proportional */
  width: calc(100vh - 95px);
  /* 95px = height of top nav + tabs */
  height: calc(100vh - 95px);
  position: fixed;
  right: 0%;
  top: 57px;
  z-index: -1;
}

.detail img {
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  padding-top: 38px;
}

/* in Conf Rooms datasets, circle that selects room */
.detail .detailHighlight {
  width: 10%;
  height: 10%;
  background-color: rgba(255, 230, 0, 0.15);
  /* box-shadow puts outline _inside_ the element rect */
  /* HIG color autodeskBlue-600 #007fc6 */
  box-shadow: inset 0px 0px 0px 3px red;
  border-radius: 50%;
  position: absolute;
  margin-top: 38px;
}

/* Responsive */
@media only screen and (orientation: portrait) {
  input[type="text"],
  select {
    width: calc(100% - 40px);
    font-size: 16px;
    margin-top: 5px;
    margin-left: 3px;
    border: 0px solid #ccc;
  }

  .location {
    font-size: 13px;
  }

  .detail {
    width: 100%;
    top: auto;
    bottom: 0px;
    height: 100vw; /* change along with #mainlist height */
    right: 0;
    left: 0;
    min-width: 0;
  }
}

.cardView {
  display: flex;
  flex-wrap: wrap;
  padding: 0 1vw;
}

.cardView > .card {
  display: flex;
  flex-direction: column;
  padding: 0;
  /* 98vw = 100vw - 1vw of padding on either side of window */
  width: calc(98vw / 5 - 1vw - 2px);
  margin: 0.5vw;
  border-radius: 2px;
  /* HIG main color: charcoal-200 */
  border: 1px solid #dcdcdc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  height: calc(98vw / 5 - 1vw - 2px);
  overflow-y: clip;
  justify-content: space-between;
}

.cardView .card .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  /* HIG main color: charcoal-100 */
  background-color: #eeeeee;
  height: 5rem;
}

.cardView .card .header .title {
  font-weight: bold;
  padding: 0 1rem;
}

.cardView .card .header .image {
  height: 100%;
}

.cardView .card .body {
  color: #3c3c3c;
  padding: 1rem;
  /* 100% of header's parent - header's height - body's padding - footer's padding - footer's height */
  height: calc(100% - 5rem - 2rem - 2rem);
}

.cardView .card .body .content {
  text-align: left;
  height: 100%;
  overflow-y: auto;
}

.cardView .card .footer {
  padding: 0rem 1rem 1rem;
}

/* Tab controls */

/* Tab headers */
.dataset-tabs__tabs {
  padding: 15px 22px 0px !important;
  /* z-index: 1; */
  background: white;
  width: 100%;
  display: flex;
}

/* Sibling div to tab headers; tab body that holds mainListWithDetail and detail */
.dataset-tabs__content {
  z-index: -1;
  flex-basis: initial;
  /* height of dataset-tabs__tabs = 15 + 23 + 1 */
  height: calc(100% - 39px);
}

/* App container */
.App {
  display: flex !important;
  flex-direction: column;
  height: 100vh;
  overflow-y: hidden;
}

/* Tab container that holds .css-buw6n9 and .css-ci7uvn */
.dataset-tabs {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  z-index: 0;
  height: calc(100% - 56px); /* 56px = height of top nav component */
  /* margin-top: 1rem; /* this is good but pushes bottom of scroll bar out of view */
}

.mLAndDetailParent {
  height: 100%;
}

.DataCard button {
  background-color: transparent;
  border: none;
  margin-top: 1rem;
  color: grey;
  text-transform: uppercase;
  padding: 0;
  font-size: 12px;
}

.DataCard .headContainer {
  /* contains title, author, time info */
  display: flex;
  padding-top: 0.75rem;
  padding-bottom: 1rem;
  /* justify-content: space-between;
  align-items: center; */
}

.DataCard .titleContainer {
  flex-grow: 1;
}

.DataCard .title {
  font-size: 1.25rem;
  line-height: normal;
  margin-bottom: 0;
  font-weight: 600;
}

.DataCard .subtitle {
  font-size: 1rem;
}

.DataCard .dataCardFieldName {
  /* same typography as field-label and flyout-label */
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: 600;
  color: rgb(128, 128, 128);
}

.DataCard .dataCardFieldValue {
}

.DataCard .headContainer .authorImage {
  /* display: flex;
  justify-content: flex-end;
  align-items: center; */
  width: calc(
    (52px + 0.5rem) * 5
  ); /* fuzzy calc based on size and count of avatars */
  flex-shrink: 0;
}

.DataCard .headContainer .authorImage .avatars {
  display: flex;
  justify-content: right;
  flex-wrap: wrap-reverse;
  height: 100%;
  align-items: flex-start;
  flex-direction: row-reverse;
  padding-right: 5px;
  padding-top: 3px; /* with 48-pixel avatars, this center-aligns well with timeframe and author names */
}

.DataCard .authorImage img {
  width: 48px; /* should match span (below) */
  height: 48px; /* should match span (below) */
  border-radius: 50%;
  margin: 0.5rem 0.5rem 0.5rem 0;
  object-fit: cover;
  border: 1px solid lightgrey;
}

.DataCard .authorImage span {
  width: 48px; /* should match img (above) */
  height: 48px; /* should match img (above) */
  border-radius: 50%;
  margin: 0.5rem 0.5rem 0.5rem 0;
  background: rgb(205, 220, 241);
  border: 1px solid lightgrey;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  font-weight: 900;
  color: white;
}

/* author and timeframe are very similar because we want them to align horizontally. */

.DataCard .timeframe {
  padding: 0 0.5rem 0.5rem 0.5rem;
  background: #f0f0f0;
  border-radius: 0.5rem;
  width: fit-content;
  display: flex;
}

.DataCard .timeframe > div {
  padding: 0.5rem;
}

.DataCard .authors {
  padding: 0 0.5rem 0.5rem 0.5rem;
  /* background: #f0f0f0; */
  display: flex;
  margin-left: -0.25rem; /* without this, doesn't left-align with title block if no time block */
}

.DataCard .authors > div {
  padding: 0.5rem 0.5rem 0.5rem 0;
}

.DataCard .headContainer .headDetails {
  display: flex;
  margin-top: 1rem;
}

.DataCard .headContainer .headDetails > div {
  margin-right: 1rem;
  padding: 0.25rem;
}

.DataCard .description {
  /* descriptive text block */
  margin-bottom: 0.75rem; /* a bit more space to separate from non-core fields */
}

.mappedFields {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: fit-content;
}

.year.timeframe {
  height: fit-content;
}
